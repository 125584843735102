// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-talent-cv-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/templates/talent_cv.tsx" /* webpackChunkName: "component---src-templates-talent-cv-tsx" */),
  "component---src-templates-dynamic-data-a-developers-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/templates/dynamic-data/a-developers.tsx" /* webpackChunkName: "component---src-templates-dynamic-data-a-developers-tsx" */),
  "component---src-templates-dynamic-data-project-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/templates/dynamic-data/project.tsx" /* webpackChunkName: "component---src-templates-dynamic-data-project-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-webpage-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/templates/webpage.tsx" /* webpackChunkName: "component---src-templates-webpage-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-de-404-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/de/404.tsx" /* webpackChunkName: "component---src-pages-de-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-fr-404-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/fr/404.tsx" /* webpackChunkName: "component---src-pages-fr-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/atlassian/pipelines/agent/build/.cache/data.json")

