import { globalHistory } from '@reach/router'
import React from 'react'

require('prismjs/themes/prism.css')

export const onRouteUpdate = ({ location, prevLocation }) => {
  const trackPageView = () => {
    window._hsq = window._hsq || [];
    const path = location ? `${location.pathname}${location.search}${location.hash}` : undefined
    window._hsq.push(['setPath', path])
    window._hsq.push(['trackPageView'])
  }

  if (typeof window.requestAnimationFrame === 'function') {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView)
    })
  } else {
    // simulate 2 requestAnimationFrame calls
    setTimeout(trackPageView, 32)
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'gatsby-route-change'
    })
  }
}


export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete()
}
